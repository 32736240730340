export class DefaultValuesCustomer implements DefaultValues {
  disclaimer: string;
  offerPdfDrawings: string;
  maxLongLength: number;
  maxShortLength: number;
  offerValidityDays: number;

  constructor(
    disclaimer: string,
    maxLongLength: number,
    maxShortLength: number,
    offerValidityDays: number,
    offerPdfDrawings: string
  ) {
    this.disclaimer = disclaimer;
    this.offerPdfDrawings = offerPdfDrawings;
    this.maxLongLength = maxLongLength;
    this.maxShortLength = maxShortLength;
    this.offerValidityDays = offerValidityDays;
  }
}
