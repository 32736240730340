
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BACKEND_URL } from '@/za_conf';
import axios from 'axios';
import CalcOfferItemInternal from '@/components/calculator/CalcOfferItemInternal.vue';
import { CustomerPartEntry } from '@/customer/part_details';
import CalcOfferItemExternal from '@/components/calculator/CalcOfferItemExternal.vue';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';

@Component({
  components: {
    calcofferiteminternal: CalcOfferItemInternal,
    calcofferitemexternal: CalcOfferItemExternal
  }
})
export default class CalcOffer extends Vue {
  editedItem: any = {};
  BACKEND_URL = BACKEND_URL;
  offerSets: any = {};
  dialogAfterVerification = false;
  showPdfPreview = false;
  pdfUrl: string | null = null;
  private saveCommentsDebounced: DebouncedFunc<(uuid: string, comment: string) => Promise<void>>;

  constructor() {
    super();
    // Create debounced version of saveComments
    this.saveCommentsDebounced = debounce(this.saveComments, 500);
  }

  get parts(): CustomerPartEntry[] {
    return Object.values(this.$store.getters.uploadedParts);
  }

  get storeOfferSets() {
    return this.$store.getters.currentOffer;
  }

  get disclaimer() {
    return this.$store.getters.defaultValues.disclaimer;
  }

  get offerPdfDrawings() {
    return this.$store.getters.defaultValues.offerPdfDrawings;
  }

  addedComment(uuid: string, comment: string) {
    this.saveCommentsDebounced(uuid, comment);
  }

  offerSelectionChanged(uuid: string, selectedIndex: number, comment: string) {
    this.$store.commit('updateCurrentOfferSelection', {
      uuid,
      selectedIndex,
      comment
    });
    this.saveCommentsDebounced(uuid, comment);
  }

  // New method to save comments
  private async saveComments(uuid: string, comment: string) {
    try {
      await axios.post(`${BACKEND_URL}/comment`, {
        offer_id: this.$store.getters.currentOfferID,
        uuid_comments: { [uuid]: comment }
      });
    } catch (error) {
      // Optionally add error handling/notification here
    }
  }

  @Watch('storeOfferSets')
  onStoreOfferSetsChange(val: any, old_val: boolean) {
    this.offerSets = Object.assign({}, val);
  }

  showVerificationDialog() {
    this.dialogAfterVerification = true;
  }

  async activateVerification(would_accept: boolean) {
    this.dialogAfterVerification = false;
    try {
      let selection = this.$store.getters.currentOfferSelection;
      await axios.post(`${BACKEND_URL}/verify`, {
        offer_id: this.$store.getters.currentOfferID,
        selection: selection,
        accepts: would_accept
      });
      this.$router.push('/');
    } catch (error) {
      // Optionally add error handling/notification here
    }
  }

  async generatePDF() {
    try {
      const response = await axios.post(
        `${BACKEND_URL}/pdf`,
        { offer_id: this.$store.getters.currentOfferID },
        { responseType: 'blob' }
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });
      this.pdfUrl = window.URL.createObjectURL(blob);
      this.showPdfPreview = true;
    } catch (error) {}
  }

  downloadPdf() {
    if (this.pdfUrl) {
      const link = document.createElement('a');
      link.href = this.pdfUrl;
      link.setAttribute('download', `${this.$store.getters.getFormattedOfferId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  @Watch('showPdfPreview')
  onShowPdfPreviewChange(newVal: boolean) {
    if (!newVal && this.pdfUrl) {
      window.URL.revokeObjectURL(this.pdfUrl);
      this.pdfUrl = null;
    }
  }

  async save() {
    try {
      await axios.post(`${BACKEND_URL}/validate`, {
        offer_part_id: this.offerSets[this.editedItem.part_name].offers[this.editedItem.index].offer_part_id,
        data: this.editedItem
      });
      await this.$store.dispatch('calculateOffer');
    } catch (error) {
      // Optionally add error handling/notification here
    }
  }

  getOnRequestPart(uuid: string) {
    return this.$store.getters.parts[uuid].on_request_details.partOnRequest();
  }

  getPriceLimitReached(uuid: string) {
    return this.$store.getters.parts[uuid].price_limit_surpassed;
  }

  get isCustomer() {
    return this.$store.getters.isCustomer;
  }

  get hasManuOrAdvancedRights() {
    return this.$store.getters.hasManuOrAdvancedRights;
  }

  // Clean up debounced function when component is destroyed
  beforeDestroy() {
    this.saveCommentsDebounced.cancel();
  }
}
