
import { Component, Vue, Watch } from 'vue-property-decorator';
import UploadCard from '@/components/util/UploadCard.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcManual from '@/components/calculator/CalcManual.vue';
import { FileEntry } from '@/customer/part_details';

interface FileNameToUUIDObj {
  [key: string]: string;
}

@Component({
  components: {
    zaUpload: UploadCard,
    zaCalcManual: CalcManual
  }
})
export default class CalcFileUpload extends Vue {
  confirmDialog = false;
  selectedFileNameToDelete = null as string | null;
  dialog = false;
  valid = false;
  currFiles: File[] = [];
  articleRules = [
    (v: string) => !!v || 'Artikelbezeichnung benötigt',
    (v: string) => v.trim().length > 0 || 'Artikelbezeichnung darf nicht leer sein'
  ];

  defaultMessage: string = 'Hochzuladende Dateien hier reinziehen oder mit Klick Dialog öffnen';
  acceptedFiles: string[] = ['.dxf', '.stp', '.step'];

  async onFilesAdded(files: File[]) {
    this.currFiles = [];

    for (const file of files) {
      if (this.$store.getters.containsFileName(file.name) && this.files[file.name].error_during_upload) {
        await this.deleteFile(file.name);
      }
      if (!this.$store.getters.containsFileName(file.name)) {
        let entry: any = {};
        entry['file'] = file;
        entry['article'] = file.name;
        entry['drawing'] = '';
        this.currFiles.push(entry);

        // Immediately add file to the store for visibility in the list
        const tempFileEntry: FileEntry = new FileEntry();
        tempFileEntry.name = file.name;
        this.$store.commit('addFile', tempFileEntry);
      }
    }

    this.dialog = true;
  }
  uploadFile(file: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.set('file', file.file);
      formData.set('article', file.article);
      formData.set('drawing', file.drawing);
      formData.set('offer_id', this.$store.getters.currentOfferID);
      formData.set('charge_size', this.$store.getters.chargeSize);

      this.$store.commit('updateProgress', { name: file.file.name, progress: 0 }); // Set initial progress

      axios
        .post(`${BACKEND_URL}/upload/cad`, formData, {
          onUploadProgress: event => this.onUploadProgress(file.file.name, event)
        })
        .then(response => {
          this.$store.commit('updateProgress', {
            name: file.file.name,
            progress: 102
          });
          this.$store.commit('setFileUUID', {
            name: file.file.name,
            uuid: response.data.uuid
          });

          // Add the parts to the store
          for (let part of response.data.parts.values()) {
            this.$store.commit('addPart', part);
          }

          resolve();
        })
        .catch(e => {
          this.$store.commit('setUploadError', {
            name: file.file.name,
            message: e.response.data.error
          });
          reject(e);
        });
    });
  }
  activateDeleteFileDialog(file_name: string) {
    this.confirmDialog = true;
    this.selectedFileNameToDelete = file_name;
  }

  cancelFileDialog() {
    this.confirmDialog = false;
    this.selectedFileNameToDelete = null;
  }
  async executeUpload() {
    this.dialog = false;

    // Function to upload files sequentially
    const uploadSequentially = async (files: any[]) => {
      for (let file of files) {
        try {
          await this.uploadFile(file);
        } catch (e) {}
      }
    };

    // Call the sequential uploader with the current files
    await uploadSequentially(this.currFiles);

    // Once all uploads are finished, trigger the calculation
    this.$store.dispatch('calculateOffer');
  }

  placeholder() {}

  async deleteFile(fileName: string) {
    this.confirmDialog = false;
    await this.$store.dispatch('deleteFile', fileName);
  }

  deletePart(name: string) {
    this.$store.commit('deleteFile', name);
  }

  onUploadProgress(file: string, progressEvent: ProgressEvent) {
    this.$store.commit('updateProgress', {
      name: file,
      progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
    });
  }

  get parts() {
    return this.$store.getters.parts;
  }

  get files() {
    return this.$store.getters.partFiles;
  }
}
